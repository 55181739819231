const NotFound = () => {
  return (
    <div>
      <br></br>
      <h1>Page Not Found</h1>
      <br></br>
      <h2>Could not find the requested resource</h2>
      <a href="/">Go Back</a>
      <br></br>
    </div>
  )
}

export default NotFound
